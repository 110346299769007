import React from "react"
//import Image from "../components/Image"
import { breakOnPeriod/* , pipesToSpan */ } from "../util/common"

function FeaturesPackBlock({ title, items, showTitle = true }) {

  if (items.length === 0) return false

  return (
    <section className="bg-dark-blue lg:py-32 py-12">
      <div className="container text-white">

        {showTitle && <h2 className="H2 max-w-5xl	 mb-16  mx-auto" dangerouslySetInnerHTML={{ __html: breakOnPeriod(title) }} />}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 lg:gap-20 lg:w-4/5 mx-auto">
          {items.map((c, i) => (
            <div className="" key={i}>
              <DarkCard {...c} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}


export default FeaturesPackBlock

const DarkCard = ({ title, description, relationships, image, ...props }) => {

  const imageTag = relationships.image ? <img src={relationships.image.localFile.publicURL} alt={image.alt} className="mx-auto lg:ml-0" loading="lazy" width={image.width} height={image.height} /> : ''

  return (
    <div className="text-center lg:text-left" >
      <div className="">{imageTag}</div>
      <div className="font-bold text-2xl mt-5 mb-2 ">{title}</div>
      <p className="mt-0 text-base lg:max-w-[330px]">{description}</p>
    </div>
  )
}




